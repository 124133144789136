import React from 'react'

import SEO from 'components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { LinkArrowIcon, LogoSpreadIcon } from 'components/icons'
import { Nav } from 'components/nav'
import { HeaderLink, LdJsonLogo } from 'components/semanticdata'
import Analytics from '../../../components/analytics'
import { Link } from 'gatsby'
import Obfuscate from 'react-obfuscate'

const BlogPage = () => (
  <Layout className="bg-primary-2">
    <Analytics />
    <SEO title="Privacy Statement for 'The Next Tech Challenge'" pageUrl="/initiatives/24-techchallenge/privacy/" type="article" description="Privacy Statement for 'The Next Tech Challenge'" cardImage="/img/tumbacard24techchallenge.png" />
    <LdJsonLogo />
    <MainWithMenu className="relative grid">
      <Header className="z-20 pt-6 h-56">
        <HeaderLink />
      </Header>
      <Deco before after decoType="3" className="bg-primary-3 h-full v-full" gridRow={2} />
      <Section className="z-20 mb-8 text-primary-2" gridRow={2}>
        <h1 className="pt-5 text-xl sm:text-2xl lg:text-3xl leading-tight">Privacy Statement for Data Collected During "The Next Tech Challenge" Initiative</h1>
        <p className="pt-5 text-sm sm:text-lg font-normal">
          At Tumba Solutions, we are committed to protecting the privacy and security of the personal data collected during this initiative. This Privacy Statement outlines how we collect, use, store, and protect your information, as well as your rights
          regarding your data.
        </p>

        <h2 className="pt-5 text-lg">1. Data Collection</h2>
        <div className="pt-5 text-sm sm:text-lg font-normal">
          <p>
            During the course of{' '}
            <strong>
              <Link to="/initiatives/24-techchallenge/">"The Next Tech Challenge"</Link>
            </strong>{' '}
            initiative, we may collect various types of personal data, including but not limited to:
          </p>
          <ul className="pl-10 mt-2 list-disc">
            <li>Contact information (e.g., name, email address, phone number)</li>
            <li>Demographic information (e.g., age, gender, location)</li>
            <li>Responses to surveys, questionnaires, or feedback forms</li>
            <li>Usage data and preferences related to the initiative</li>
          </ul>
        </div>

        <h2 className="pt-5 text-lg">2. Purpose of Data Collection</h2>
        <div className="pt-5 text-sm sm:text-lg font-normal">
          <p>The data collected will be used for the following purposes:</p>
          <ul className="pl-10 mt-2 list-disc">
            <li>To administer and manage the initiative</li>
            <li>To communicate with participants and provide updates</li>
            <li>To analyze and improve the effectiveness of the initiative</li>
            <li>To fulfill any legal or regulatory requirements</li>
          </ul>
        </div>

        <h2 className="pt-5 text-lg">3. Data Sharing and Disclosure</h2>
        <div className="pt-5 text-sm sm:text-lg font-normal">
          <p>We will not share your personal data with third parties, except in the following circumstances:</p>
          <ul className="pl-10 mt-2 list-disc">
            <li>With service providers who assist in operating the initiative, under strict confidentiality agreements</li>
            <li>As required by law, such as in response to a court order or legal process</li>
            <li>With your explicit consent, for any other purpose</li>
          </ul>
        </div>

        <h2 className="pt-5 text-lg">4. Data Storage and Security</h2>
        <p className="pt-5 text-sm sm:text-lg font-normal">
          We take the security of your data seriously. All personal data collected will be stored securely using appropriate technical and organizational measures to prevent unauthorized access, disclosure, alteration, or destruction. We will retain your data
          only for as long as necessary to fulfill the purposes for which it was collected, or as required by law.
        </p>

        <h2 className="pt-5 text-lg">5. Your Rights</h2>
        <div className="pt-5 text-sm sm:text-lg font-normal">
          <p>You have the following rights concerning your personal data:</p>
          <ul className="pl-10 mt-2 list-disc">
            <li>The right to access your data and request a copy of it</li>
            <li>The right to request corrections to any inaccurate or incomplete data</li>
            <li>The right to request the deletion of your data, subject to legal or contractual restrictions</li>
            <li>The right to withdraw your consent for data processing at any time</li>
            <li>The right to object to the processing of your data or to request restrictions on its processing</li>
            <li>The right to data portability, allowing you to obtain and reuse your data across different services</li>
          </ul>
        </div>

        <h2 className="pt-5 text-lg">6. Changes to This Privacy Statement</h2>
        <p className="pt-5 text-sm sm:text-lg font-normal">
          We may update this Privacy Statement from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on our website, and, where appropriate, we will notify you via email.
        </p>

        <h2 className="pt-5 text-lg">7. Contact Information</h2>
        <div className="pt-5 text-sm sm:text-lg font-normal">
          <p>If you have any questions, concerns, or requests regarding your personal data or this Privacy Statement, please contact us at:</p>
          <p className="pt-5 text-sm sm:text-lg font-mono">
            Tumba Solutions
            <br />
            <Obfuscate email="contact+privacy@tumba.solutions" headers={{ subject: 'The Next Tech Challenge' }}>
              contact+privacy AT tumba.solutions
            </Obfuscate>
          </p>
        </div>
      </Section>
      <Section className="z-20 mt-40" gridRow={3}>
        <LogoSpreadIcon className="m-auto h-20" />
      </Section>
      <Deco className="bg-primary-1" gridRow={3} />
      <Nav className="z-20 text-primary-2" gridRow={4} />
    </MainWithMenu>
  </Layout>
)

export default BlogPage
